import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../services/instance";

const initialState = {
    // transactions 
    allTransactionsLoading: false,
    allTransactions: [],
    singleTransactionsLoading: false,
    singleTransactions: null,
    exportTransactionsCsvLoading: false,

    // walletData
    walletData: null,
    walletDataLoading: false
};

//^ ========= get all wallet Transactions ============//
export const getAllTransactions = createAsyncThunk("wallet/getAllTransactions", async (params) => {
    try {
        const response = await axiosInstance.get("wallet/transactions", { params });
        return response?.data?.data;
    } catch (error) {
        console.error(error);
    }
}
);

//^ ========= get all Transactions ============//
export const getWalletData = createAsyncThunk("wallet/getWalletData", async (params) => {
    try {
        const response = await axiosInstance.get("wallet/details");
        return response?.data?.data;
    } catch (error) {
        console.error(error);
    }
}
);

/*-----------------------Slice----------------*/

const TransactionsSlice = createSlice({
    name: "Transactions",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // get Transactions
        builder
            .addCase(getAllTransactions.pending, (state) => {
                state.allTransactionsLoading = true;
            })
            .addCase(getAllTransactions.fulfilled, (state, action) => {
                state.allTransactionsLoading = false;
                state.allTransactions = action.payload;
            })
            .addCase(getAllTransactions.rejected, (state, action) => {
                state.allTransactionsLoading = false;
            })

            // get wallet data 
            .addCase(getWalletData.pending, (state) => {
                state.walletDataLoading = true;
            })
            .addCase(getWalletData.fulfilled, (state, action) => {
                state.walletDataLoading = false;
                state.walletData = action.payload;
            })
            .addCase(getWalletData.rejected, (state, action) => {
                state.walletDataLoading = false;
            });
    },
});

export default TransactionsSlice.reducer;
